export const SET_LOADING_GENERAL = "V1_SET_LOADING_GENERAL";
export const UNSET_LOADING_GENERAL = "V1_UNSET_LOADING_GENERAL";

export const SET_LOADING_PENDING_TABLE = "V1_SET_LOADING_PENDING_TABLE";
export const UNSET_LOADING_PENDING_TABLE = "V1_UNSET_LOADING_PENDING_TABLE";

export const SET_LOADING_COMPLETED_TABLE = "V1_SET_LOADING_COMPLETED_TABLE";
export const UNSET_LOADING_COMPLETED_TABLE = "V1_UNSET_LOADING_COMPLETED_TABLE";

export const SET_LOADING_ORDER_LOG = "V1_SET_LOADING_ORDER_LOG";
export const UNSET_LOADING_ORDER_LOG = "V1_UNSET_LOADING_ORDER_LOG";

export const SET_LOADING_HUBS = "V1_SET_LOADING_HUBS";
export const UNSET_LOADING_HUBS = "V1_UNSET_LOADING_HUBS";

export const SET_LOADING_COUNT_HUBS = "V1_SET_LOADING_COUNT_HUBS";
export const UNSET_LOADING_COUNT_HUBS = "V1_UNSET_LOADING_COUNT_HUBS";

export const SET_LOADING_GET_HUB_THRU_OPUID = "V1_SET_LOADING_GET_HUB_THRU_OPUID"
export const UNSET_LOADING_GET_HUB_THRU_OPUID = 'V1_UNSET_LOADING_GET_HUB_THRU_OPUID'

export const SET_LOADING_PARK_ORDER = "V1_SET_LOADING_PARK_ORDER";
export const UNSET_LOADING_PARK_ORDER = "V1_UNSET_LOADING_PARK_ORDER";

export const SET_LOADING_PARK_ORDER_COMMENT = "V1_SET_LOADING_PARK_ORDER_COMMENT";
export const UNSET_LOADING_PARK_ORDER_COMMENT = "V1_UNSET_LOADING_PARK_ORDER_COMMENT";

export const SET_LOADING_GET_ORDER_THRU_SCAN = "V1_SET_LOADING_GET_ORDER_THRU_SCAN"
export const UNSET_LOADING_GET_ORDER_THRU_SCAN = "V1_UNSET_LOADING_GET_ORDER_THRU_SCAN"

export const SET_LOADING_REPORT = 'V1_SET_LOADING_REPORT'
export const UNSET_LOADING_REPORT = 'V1_UNSET_LOADING_REPORT'