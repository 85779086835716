import * as ActionTypes from "../action-types/style";

export const setLoadingGeneral = () => {
    return {
        type: ActionTypes.SET_LOADING_GENERAL,
    };
};

export const unsetLoadingGeneral = () => {
    return {
        type: ActionTypes.UNSET_LOADING_GENERAL,
    };
};

export const setLoadingOrderLog = () => {
    return {
        type: ActionTypes.SET_LOADING_ORDER_LOG,
    };
};

export const unsetLoadingOrderLog = () => {
    return {
        type: ActionTypes.UNSET_LOADING_ORDER_LOG,
    };
};

export const setLoadingHub = () => {
    return {
        type: ActionTypes.SET_LOADING_HUBS,
    };
};

export const unsetLoadingHub = () => {
    return {
        type: ActionTypes.UNSET_LOADING_HUBS,
    };
};

export const setLoadingCountHub = () => {
    return {
        type: ActionTypes.SET_LOADING_COUNT_HUBS,
    };
};

export const unsetLoadingCountHub = () => {
    return {
        type: ActionTypes.UNSET_LOADING_COUNT_HUBS,
    };
};

export const setLoadingGetHubThruOPUID = () => {
    return {
        type: ActionTypes.SET_LOADING_GET_HUB_THRU_OPUID
    }
}

export const unsetLoadingGetHubThruOPUID = () => {
    return {
        type: ActionTypes.UNSET_LOADING_GET_HUB_THRU_OPUID
    }
}

export const setLoadingPending = () => {
    return {
        type: ActionTypes.SET_LOADING_PENDING_TABLE,
    };
};

export const unsetLoadingPending = () => {
    return {
        type: ActionTypes.UNSET_LOADING_PENDING_TABLE,
    };
};

export const setLoadingCompleted = () => {
    return {
        type: ActionTypes.SET_LOADING_COMPLETED_TABLE,
    };
};

export const unsetLoadingCompleted = () => {
    return {
        type: ActionTypes.UNSET_LOADING_COMPLETED_TABLE,
    };
};

export const setLoadingParkOrder = () => {
    return {
        type: ActionTypes.SET_LOADING_PARK_ORDER,
    };
};

export const unsetLoadingParkOrder = () => {
    return {
        type: ActionTypes.UNSET_LOADING_PARK_ORDER,
    };
};

export const setLoadingorderComment = () => {
    return {
        type: ActionTypes.SET_LOADING_PARK_ORDER_COMMENT,
    };
};

export const unsetLoadingorderComment = () => {
    return {
        type: ActionTypes.UNSET_LOADING_PARK_ORDER_COMMENT,
    };
};

export const setLoadingGetOrderThruScan = () => {
    return {
        type: ActionTypes.SET_LOADING_GET_ORDER_THRU_SCAN,
    };
};

export const unsetLoadingGetOrderThruScan = () => {
    return {
        type: ActionTypes.UNSET_LOADING_GET_ORDER_THRU_SCAN,
    };
};

export const setLoadingReport = () => {
    return { 
        type: ActionTypes.SET_LOADING_REPORT
    }
}

export const unsetLoadingReport = () => {
    return {
        type: ActionTypes.UNSET_LOADING_REPORT
    }
}