// React Thirty Party Library
import { Tag } from "antd";
// import { useDispatch } from "react-redux";
import { CheckCircleFilled } from "@ant-design/icons";

// Constant
import { TEMPLATE } from "../..";
import { ROOT_CONSTANT } from "../../../constants";

// Components
import { ButtonLink } from "../../../components";

//handlers
import { filter } from "../../../handlers";

// Service
import { TIME } from "../../../services/util";

const { filterHDLR, searchHDLR } = filter;

const { parseTimeMinDayFirst } = TIME;

const { PENDING_TABLE_LIST_INDEX, PENDING_TABLE_LIST_TITLE } =
  TEMPLATE.TABLELIST;

const { icon_comment_parcel } = ROOT_CONSTANT.IMG.ICON;

export const PENDING_COLUMN = ({
  redux = {},
  func = {},
  exportState = false,
  //   status = ""
}) => {
  const { orderStatuses, hubs, customers, uroleid, points, comments } = redux;

  const {
    NavigateOrderSummary,
    SORenavigate,
    setIsOpen,
    setData,
    handleSearch,
  } = func;

  return [
    {
      title: PENDING_TABLE_LIST_TITLE.ordid,
      key: PENDING_TABLE_LIST_INDEX.ordid,
      width: 160,
      fixed: "left",
      render: (record) => {
        const { ordid, sorted } = record;

        return exportState ? (
          ordid
        ) : (
          <div style={{ display: "flex", gap: "9px" }}>
            <ButtonLink onClick={() => NavigateOrderSummary(ordid)}>
              {ordid}
            </ButtonLink>
            <CheckCircleFilled
              style={{ color: sorted ? "#198754" : "#adb5bd" }} 
            />
          </div>
        );
      },
      sorter: (a, b) => {
        a = parseInt(a.ordid.replace("P", ""));
        b = parseInt(b.ordid.replace("P", ""));

        return a - b;
      },
    },
    {
      title: PENDING_TABLE_LIST_TITLE.status,
      key: PENDING_TABLE_LIST_INDEX.status,
      width: 380,
      render: (rowData) => {
        const { status, ordid, add_on } = rowData;

        const statusName = orderStatuses[status]?.display.toUpperCase();
        const colorTag = orderStatuses[status]?.colorTag;

        // console.log(ordid, status, statusName, add_on)

        return exportState ? (
          statusName
        ) : (
          <Tag color={colorTag} key={status}>
            {statusName}
          </Tag>
        );
      },
      filters: filterHDLR.getColumnsFilterStatus(orderStatuses),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: PENDING_TABLE_LIST_TITLE.Delivery_Instruction,
      key: PENDING_TABLE_LIST_INDEX.Delivery_Instruction,
      width: 230,
      render: (rowData) => {
        const { deliveryType, origid, dstid, origpid, dstpid, uroleid } =
          rowData;
        const orig = "H" + ((hubs[origid] && hubs[origid].code) || "-");
        const dest = "H" + ((hubs[dstid] && hubs[dstid].code) || "-");
        const origPoint =
          "P" + ((points[origpid] && points[origpid].code) || "-");
        const destPoint =
          "P" + ((points[dstpid] && points[dstpid].code) || "-");

        const route =
          deliveryType !== "p2p"
            ? `${orig}-${dest}`
            : `${origPoint}-${orig}-${dest}-${destPoint}`;
        const type = deliveryType !== "p2p" ? "Hub to Hub" : "Point to Point";
        return exportState ? (
          (deliveryType, route)
        ) : (
          <>
            {/* <p>{DATA_FIELD_VALUES["deliveryType"][deliveryType]}</p> */}
            <p>{type}</p>
            <p>{route}</p>
          </>
        );
      },
    },
    {
      title: PENDING_TABLE_LIST_TITLE.customer_instruction,
      width: 200,
      render: (rowData) => {
        const { add_on, origid, deliveryType, dstid, origpid, dstpid } =
          rowData;

        return exportState ? null : (
          <>
            {deliveryType === "p2p" ? (
              <>
                <p>{`Self Lodge At Point ${
                  (points[origpid] && points[origpid].code) || "-"
                }`}</p>
                <p>{`Self Collect At Point ${
                  (points[dstpid] && points[dstpid].code) || "-"
                }`}</p>
              </>
            ) : Array.isArray(add_on) &&
              add_on.length > 0 &&
              add_on.every((value) => value !== null) ? (
              <>
                <p>Doorstep Pickup</p>
                <p>
                  {deliveryType === "h2h"
                    ? `Self Collect At Hub ${hubs[dstid] && hubs[dstid].code}`
                    : "Doorstep Drop Off"}
                </p>
              </>
            ) : (
              <>
                <p>{`Self Lodge At Hub ${
                  hubs[origid] && hubs[origid].code
                }`}</p>
                <p>
                  {deliveryType === "h2h"
                    ? `Self Collect At Hub ${hubs[dstid] && hubs[dstid].code}`
                    : "Doorstep Drop Off"}
                </p>
              </>
            )}
          </>
        );
      },
    },

    // uroleid === 'POP'
    // &&
    {
      title: PENDING_TABLE_LIST_TITLE.origpid,
      key: PENDING_TABLE_LIST_INDEX.origpid,
      width: 150,
      render: (rowData) => {
        const { origpid, etd, deliveryType } = rowData;

        // const origin = deliveryType === 'p2p' ? 'P' + (points[origpid] && points[origpid].code || '-') : "-"
        const origin = points[origpid]
          ? "P" + ((points[origpid] && points[origpid].code) || "-")
          : "-";
        // const date = deliveryType === 'p2p' ? TIME.parseTimeMinDayFirst(etd) : '-'
        const date = points[origpid] ? TIME.parseTimeMinDayFirst(etd) : "-";

        return exportState ? (
          origin
        ) : (
          <>
            <p>{origin}</p>
            <p>{date}</p>
          </>
        );
      },
      filters: filterHDLR.getColumnsFilterHub(points),
      onFilter: (value, record) => record.origpid === value,
    },

    {
      title: PENDING_TABLE_LIST_TITLE.origid,
      key: PENDING_TABLE_LIST_INDEX.origid,
      width: 150,
      render: (rowData) => {
        const { origid, etd, origpid } = rowData;

        // const orig = "H" + (hubs[origid] && hubs[origid].code);
        const orig = "H" + ((hubs[origid] && hubs[origid].code) || "-");
        const etdOrigid = (!origpid && parseTimeMinDayFirst(etd)) || "-";

        return exportState ? (
          orig || "-"
        ) : (
          <>
            <p>{orig || "-"}</p>
            <p>{etdOrigid}</p>
          </>
        );
      },
      filters: filterHDLR.getColumnsFilterHub(hubs),
      onFilter: (value, record) => record.origid === value,
    },
    {
      title: PENDING_TABLE_LIST_TITLE.dstid,
      key: PENDING_TABLE_LIST_INDEX.dstid,
      width: 150,
      render: (rowData) => {
        const { dstid, eta, dstpid } = rowData;

        const dest = "H" + ((hubs[dstid] && hubs[dstid].code) || "-");
        const etdDstid = (!dstpid && parseTimeMinDayFirst(eta)) || "-";

        return exportState ? (
          dest || "-"
        ) : (
          <>
            <p>{dest || "-"}</p>
            <p>{etdDstid}</p>
          </>
        );
      },
      filters: filterHDLR.getColumnsFilterHub(hubs),
      onFilter: (value, record) => record.dstid === value,
    },
    // uroleid === 'POP'
    // &&
    {
      title: PENDING_TABLE_LIST_TITLE.dstpid,
      key: PENDING_TABLE_LIST_INDEX.dstpid,
      width: 180,
      render: (rowData) => {
        const { dstpid, eta, deliveryType } = rowData;

        const dest =
          deliveryType === "p2p"
            ? "P" + ((points[dstpid] && points[dstpid].code) || "-")
            : "-";
        const date =
          deliveryType === "p2p" ? TIME.parseTimeMinDayFirst(eta) : "-";

        return exportState ? (
          dest
        ) : (
          <>
            <p>{dest}</p>
            <p>{date}</p>
          </>
        );
      },
      filters: filterHDLR.getColumnsFilterHub(points),
      onFilter: (value, record) => record.dstpid === value,
    },
    {
      title: PENDING_TABLE_LIST_TITLE.parcel,
      key: PENDING_TABLE_LIST_INDEX.parcel,
      width: 250,
      render: (rowData) => {
        const { parcel = {}, sorted } = rowData;
        const { sort: newSort = [], sort: oldSort = {}, unsort = [] } = parcel;

        const parcelNew = (arr) => {
          if (!Array.isArray(arr)) {
            return {};
          }

          return arr.reduce((acc, p) => {
            return {
              length: p.length,
              width: p.width,
              height: p.height,
              weight: p.weight,
            };
          }, {});
        };

        const volume = (par) =>
          parseFloat(par.length || 0) *
          parseFloat(par.width || 0) *
          parseFloat(par.height || 0);

        const newDataParcelUnsort = parcelNew(unsort || []);
        const unsortVolumeNew = volume(newDataParcelUnsort);
        const unsortVolumeOld = volume(parcel);

        const estVolume = unsort ? unsort && unsortVolumeNew : unsortVolumeOld;
        const volumeEstTotal =
          estVolume !== 0 ? `${estVolume / 1000000}m3` : `${0}m3`;

        const newDataParcelSort = parcelNew(newSort || []);
        const sortVolumeNew = volume(newDataParcelSort);
        const sortVolumeOld = volume(oldSort);

        const sortVolume = newSort ? newSort && sortVolumeNew : sortVolumeOld;
        const volumeSortTotal =
          sortVolume !== 0 ? `${sortVolume / 1000000}m3` : `${0}m3`;

        const totalWeight = (par) =>
          Object.values(par)
            .reduce((sum, value) => {
              const weight = parseFloat(value) || 0;
              return parseFloat(sum) + weight;
            }, 0)
            .toFixed(2);

        const oldWeight = parcel && unsort ? parcel : {};
        const unsortWeightNew = totalWeight(newDataParcelUnsort);
        const unsortWeightOld = totalWeight(oldWeight);

        const estWeight = unsort ? unsort && unsortWeightNew : unsortWeightOld;
        const weightEstTotal = estWeight !== 0 ? `${estWeight}kg` : `${0}kg`;

        const sortWeightNew = totalWeight(newDataParcelSort);
        const sortWeightOld = totalWeight(oldSort || {});

        const sortWeight = oldSort ? oldSort && sortWeightNew : sortWeightOld;
        const weightSortTotal = sortWeight !== 0 ? `${sortWeight}kg` : `${0}kg`;

        const unsortQuantity = parcel.total_pieces;
        const estQuantity = unsortQuantity
          ? unsortQuantity && unsortQuantity
          : 1;

        const est = `${estQuantity}/ ${volumeEstTotal}/ ${weightEstTotal} `;
        const sortData = `${estQuantity}/ ${volumeSortTotal}/ ${weightSortTotal}`;

        return exportState ? (
          {
            /**/
          }
        ) : (
          <>
            <p>Est: {est}</p>
            {sorted === 1 ? <p>Sorted: {sortData}</p> : <p>Sorted: {"-"}</p>}
          </>
        );
      },
    },
    {
      title: PENDING_TABLE_LIST_TITLE.memid,
      key: PENDING_TABLE_LIST_INDEX.memid,
      width: 250,
      render: (rowData) => {
        const { memid } = rowData;

        const customer = `${memid}/ ${
          (customers[memid] &&
            customers[memid].name + " / " + customers[memid]?.contact) ||
          "-"
        }`;

        return exportState ? customer : <>{customer}</>;
      },
      ...searchHDLR.getColumnSearchProps("memid", handleSearch),
    },
    {
      title: PENDING_TABLE_LIST_TITLE.sr,
      key: PENDING_TABLE_LIST_INDEX.sr,
      width: 200,
      render: (rowData) => {
        const { sender, receiver } = rowData;

        const senderName =
          (sender &&
            `${sender.name} (${sender.contactPrefix}-${sender.contact})`) ||
          "-";
        const receiverName =
          (receiver &&
            `${receiver.name} (${receiver.contactPrefix}-${receiver.contact})`) ||
          "-";

        return exportState ? (
          `${senderName} / ${receiverName}`
        ) : (
          <>
            <p>{senderName} /</p>
            <p>{receiverName}</p>
          </>
        );
      },
    },
    {
      title: PENDING_TABLE_LIST_TITLE.remark,
      key: PENDING_TABLE_LIST_INDEX.remark,
      width: 180,
      render: (render) => {
        const { ordid, status, parcel = {} } = render;

        return exportState ? (
          parcel.remark
        ) : (
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div>{comments[ordid]?.length || 0}</div>

            {/* <ButtonLink
              onClick={() => {
                setIsOpen(true);
                setData({ ordid, status });
              }}
            > */}
            <img src={icon_comment_parcel} alt="" />
            {/* </ButtonLink> */}

            <div>{parcel.remark || "-"}</div>
          </div>
        );
      },
    },
    {
      title: PENDING_TABLE_LIST_TITLE.SOPrinted,
      key: PENDING_TABLE_LIST_INDEX.SOPrinted,
      width: 150,
      render: (rowData) => {
        const { soid, printedAt } = rowData;

        return exportState ? (
          parseTimeMinDayFirst(printedAt)
        ) : (
          <>
            <ButtonLink onClick={() => SORenavigate(soid)}>{soid}</ButtonLink>
            <p>{parseTimeMinDayFirst(printedAt)}</p>
          </>
        );
      },
    },
    {
      title: PENDING_TABLE_LIST_TITLE.updatedAt,
      key: PENDING_TABLE_LIST_INDEX.updatedAt,
      width: 180,
      render: (record) => {
        const { updatedAt } = record;

        return exportState ? (
          parseTimeMinDayFirst(updatedAt)
        ) : (
          <>{parseTimeMinDayFirst(updatedAt)}</>
        );
      },
      sorter: (a, b) => a.updatedAt - b.updatedAt,
    },
    {
      title: PENDING_TABLE_LIST_TITLE.createdAt,
      key: PENDING_TABLE_LIST_INDEX.createdAt,
      width: 180,
      render: (record) => {
        const { createdAt } = record;

        return exportState ? (
          parseTimeMinDayFirst(createdAt)
        ) : (
          <>{parseTimeMinDayFirst(createdAt)}</>
        );
      },
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
  ];
};
