// React Thirty Party Library
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, message } from "antd";

// Constants Library
import { TEMPLATE, ROUTES, COMMON_FEATURES } from "../../../../constants";

// Components Library
import {
  TableList,
  ButtonLink,
  DrawerComments,
} from "../../../../components";
import { ActionItems } from "./action";
import ProgressTable from "../../../ProgressTable";

// Handler Library
import { orderHDLR } from "../../../../handlers";

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";
import { useNavigate } from "react-router";

const { reduxStoreWrapper } = REDUX_UTIL;
const { ROUTE_URL } = ROUTES;

const {
    PENDING_TABLE_LIST_INDEX,
    SELECT_OPTION_DAYS,
} = TEMPLATE.TABLELIST;
const { PENDING_COLUMN } = TEMPLATE.REPORT.PENDING;
const { MODALS_ORDER_UPDATE } = TEMPLATE.MODAL


const TablePending = ({
    filteredData,
    setFilteredData,
    dataSource,
    number,
    selectedRowKeys,
    setSelectedRowKeys
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [ isOpen, setIsOpen ] = useState(false); // state for DrawerComments, to decide whether the Drawer opened or closed
    const [ data, setData ] = useState({}); // Initial data needed by DrawerComments
    
    const orderStatuses = useSelector(reduxStoreWrapper("orderStatus.byID"));
    const orderComment = useSelector(reduxStoreWrapper("orderComment.byCreatedAt"))
    const hubs = useSelector(reduxStoreWrapper("hubs.byID"));
    const points = useSelector(reduxStoreWrapper("points.byID"));
    const customers = useSelector(reduxStoreWrapper("users_customer.byID"))
    const loading = useSelector(reduxStoreWrapper("style.loading.pending"));
    const uroleid = useSelector(reduxStoreWrapper('user.uroleid'))

    const { SORenavigate } = orderHDLR // Reusable Function in orderHDLR

    // Function to go to Timeline page
    const NavigateOrderTimeline = (ordid, status, record = {}) => {
        navigate(ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.ORDER_TIMELINE], {
            state: { ordid, status, record },
        });
    };

    const NavigateOrderSummary = (ordid) => {
        navigate(ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.ORDER_SUMMARY], { state: { ordid } });
    };

    // handler for search, for filtering data shown in table
    const handleSearch = (name, keyword) => {
        const filteredKeyword = dataSource.filter(order => order[name]?.includes(keyword))

        setFilteredData(filteredKeyword)
    };
    
    const columns = [
        ...PENDING_COLUMN({
            redux: {
                orderStatuses: orderStatuses || {},
                hubs: hubs || {},
                points: points || {},
                customers: customers || {},
                uroleid: uroleid || '',
                comments: orderComment || {}
            },
            func: {
                SORenavigate: (soid) => dispatch(SORenavigate(soid)),
                NavigateOrderSummary,
                handleSearch
            },
        }),
        {
        title: "Action",
        width: 100,
        fixed: "right",
        render: (record) => {

            const { status, deliveryType, add_on } = record

            const ORDER_UPDATE = MODALS_ORDER_UPDATE(status, deliveryType, add_on)

            const nextStatus = ORDER_UPDATE?.next_status
            const action_link = number === 1 ? 'Check in' : 'Check out'

            // Add Comment Handler 
            const addCommentHDLR = ({ordid, status}) => {

                setIsOpen(true)
        
                dispatch(REDUX_ACTION.v1_orderComment.get_order_comment_request(ordid))
                setData({ordid, status})
            }

            // Print Shipment Order Handler  
            const SORenavigateHDLR = (ordid) => {

                message.loading("Getting shipment order...", 0)

                dispatch(
                    REDUX_ACTION.v1_order.get_shipment_order_thru_ordid_request(
                        ordid,
                        () => message.destroy()
                    )
                );

            }

            // List of Action links
            const menuItems = ActionItems(
                record,
                addCommentHDLR,
                SORenavigateHDLR,
                nextStatus,
                action_link
            )

            // Main render in Action links
            return (
                <Dropdown
                    menu={{
                        items: menuItems, // List of Action Link used here
                    }}
                >
                    <ButtonLink>Action</ButtonLink>
                </Dropdown>
            );
        },
        },
    ];

    const expandedRowRender = (record) => {
        
        return (
            <ProgressTable record={record} hubs={hubs} points={points} NavigateOrderTimeline={NavigateOrderTimeline} />
        );
    };

    const sortedData = filteredData.sort((a, b) => b.updatedAt - a.updatedAt);

    return (
        <>
            <DrawerComments data={data} isOpen={isOpen} setIsOpen={setIsOpen} />
            <TableList.DataList
                dataSource={dataSource}
                filteredData={sortedData}
                setFilteredData={setFilteredData}
                optionDay={SELECT_OPTION_DAYS}
                dataDate={PENDING_TABLE_LIST_INDEX.createdAt}
                defaultValue="Created At"
                rowSelection={{
                    selectedRowKeys,
                    onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys)
                }}
                columnData={columns.map((c) => ({
                    ...c,
                    textWrap: "word-break",
                    ellipsis: true,
                }))}
                loading={loading}
                x={2200}
                y={1000}
                expandedRowRender={expandedRowRender}
            />
        </>
    );
};

export default TablePending;
